<template>
  <div class="persons page">
    <div class="header">
      <a @click="$router.go(-1)" class="go-back"><i class="fas fa-chevron-left"></i></a>
      <span class="header__title">Избранное</span>
    </div>
    <div class="container">
      <div class="persons-list">
        <router-link 
          to="/person" 
          class="person-link" 
          v-for="person in favoritePersons"
        >
          <div class="person" @click="$emit('openPerson', person.id)">
            <div class="person__image">
              <img :src="person.img" alt="" v-if="person.img">
              <img src="@/assets/images/empty-img.png" alt="" class="empty-image" v-else>
            </div>
            <div class="person__info">
              <span class="person__name">{{person.title}}</span>
              <span class="person__dob" v-if="person.born">{{person.born}} <i v-if="person.died">-</i> {{person.died}}</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      persons: null,
      favoritePersons: [],
    }
  },
  mounted() {
    if (localStorage.getItem('wishlist')) {
      this.favoritePersons = JSON.parse(localStorage.getItem('wishlist'));
    }
  },
}
</script>

<style lang="scss">
  @import "../assets/css/home.scss";
</style>
